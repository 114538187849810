import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";

import './index.scss';

import About from './pages/about';
import Cart from './pages/cart';
import Checkout from './pages/checkout';
import Error404 from './pages/404';
import Home from './pages/home';
import Product from './pages/product';
import Products from './pages/products';
import Contact from './pages/contact';
import Complete from './pages/complete';
import Privacy from './pages/privacy';
import Search from './pages/search';
import Resource  from './pages/resource';
import Works from './pages/works';

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: '#232363',
      contrastText: '#fff',
    },
    secondary: {
      main: '#32BA69',
      contrastText: '#000',
    },
    whiteness: {
      main: '#FFFFFF',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: `'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  }
});

/*const routes = [
  {
    path: "/",
    page: <Home/>,
    exact: true,
  },
  {
    path: "/products",
    page: <Products/>,
    exact: true,
  },
  {
    path: "/product/:id",
    page: <Product/>,
    exact: true,
  },
  {
    path: "/checkout",
    page: <Checkout/>,
    exact: true,
  },
  {
    path: "/about",
    page: <About/>,
    exact: true,
  },
];

const Wrap = (page) => 
  <>
    <Header />
    {page}
    <Footer/>
  </>
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
      <Helmet titleTemplate="%s - Verity Publishing" defaultTitle="Verity Publishing"/>
        <BrowserRouter>
          <Routes>
            {/* {routes.map(({exact, path, page}) => <Route key={path} exact={exact} path={path} element={Wrap(page)}/>)} */}
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/about" element={<About />} />
            <Route path="/cart" element={<Cart/>} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/complete" element={<Complete />} />
            <Route path="/help" element={<Contact />} />
            <Route path="/feedback" element={<Contact feedback={true} />} />,
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/search.aspx" element={<Search />} />
            <Route path="/resource" element={<Resource />} />
            {/* turn off until authentication is added <Route path="/works" element={<Works />} /> */}
            <Route path="*" element={<Error404 />} />
            {/* <Route path="/product/:id" element={Wrap(<Product />)} /> */}
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);

