import { Box, Container, Grid, Link, Paper, Typography } from "@mui/material";
import React from "react";

import VPlogo from "../assets/logo.png"

export default function Footer() {
  // , background: "linear-gradient(0deg, rgb(67 67 161 / 0%), rgb(67 67 161 / 100%))"
  const mapLinks = (arr) => arr.map(([href, title]) => 
    <Link key={href} sx={{ color: "black", paddingBottom: "20px" }} variant="body2" underline="none" href={`/${href}`}>{title}</Link>
  );

  return (<Box className="footer" sx={{ padding: "40px 20px" }}>
    <Container sx={{ maxWidth: "1280px" }} maxWidth="1280px" >
      <Grid container spacing={2}>
        <Grid item xs sm={2}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {mapLinks([["", "Home"], ["about", "About Us"], ["products", "Our Products"]])}
          </Box>
        </Grid>
        <Grid item xs sm={3}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {mapLinks([["feedback", "Feedback"], ["help", "Help"], ["privacy", "Privacy Policy"]])}  
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">
            © {new Date(Date.now()).getFullYear()} by <Link underline="none" href="https://veritypublishing.com">Verity Publishing, Inc.</Link>
          </Typography>
          <Typography variant="body2">All rights reserved.</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", }}>
            <a href="https://veritypublishing.com">
              <img src={VPlogo} alt="Verity Publishing logo" width="107px" height="60px" />
            </a>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper elevation={24} sx={{ width: "135px", height: "200px" }}>
              <Link href="https://discoveringchrist.net"><img src={'/images/discover.jpg'} alt="book cover" width="135px" height="200px" /></Link>
              <Link variant="caption" href="https://discoveringchrist.net">DiscoveringChrist.net</Link>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>);
}