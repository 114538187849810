import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Box, Button, Card, CardContent, CardActions, Grid, Link, Typography } from "@mui/material";

import Footer from "../components/footer";
import Header from "../components/header";
import { productList } from "../components/productList";
import { categoryList } from "../components/categoryList";

import "./home.scss";

export default function Home() {
  const navigate = useNavigate();

  const goToProductPage = (id) => { 
    navigate(`/product/${id}`);
  }

  return (<HelmetProvider>
    <Helmet title="Home"/>
    <Header />
    <Box className="homeBackground" sx={{ padding: 0, backgroundPosition: "top", backgroundSize: "cover" }} >
      <Box sx={{ width: "100%", overflowY: "auto", maxHeight: "calc(100vh - 70px)", textAlign: "-webkit-center" }}>
        <Box className="homeScroll" sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px", maxWidth: "1280px", textAlign: "left" }}>
          <Link href="/product/50"><img style={{ float: "right", padding: "20px" }} height="250px" src="/images/discover.jpg" alt="Discovering Christ cover" /></Link>
          <h1>New Release</h1>
          <Typography sx={{marginBottom: "10px"}}>We are pleased to announce the release of our newest book, <i>Discovering Christ—Developing the Attributes of our Savior Jesus Christ</i>.</Typography>
          <Typography>This book enables the reader to dig deeper into the personality of our Savior by studying each of His attributes. Each chapter focuses on one attribute, and then concludes with powerful suggestions about how we can add these attributes to our own lives.</Typography>
          <Typography>Find out more about this fascinating new book <Link href="/product/50">here</Link>.</Typography>
          <Typography>We have also created a website where you can set goals and track them as you seek to become more like our Savior Jesus Christ. You can have reminders of these goals sent to your email or sent as texts to your phone. You can check out this new website <Link href="https://www.discoveringchrist.net">here</Link>.</Typography>
          <h1>Our Products</h1>
          <Typography>Our authors have covered many different subjects in the books they have written, which can be divided into the following categories:</Typography>
          {categoryList.filter(c => c.Hidden === false).sort((a, b) => a.Priority < b.Priority ? -1 : 1)
            .map((category, cIndex) => (<div key={cIndex}>
              <h2>{category.Name}</h2>
              <Grid container spacing={3}>
                {productList.filter(p => p.Hidden === false && p.CategoryIDs.includes(category.CategoryID))
                .sort((a, b) => a.Popularity < b.Popularity ? -1 : 1)
                .map((product, index) => (
                  <Grid item xs={6} sm={2} key={index}>
                    <Card elevation={8}>
                      <Box sx={{ margin: "20px 15px 0 15px" }}>
                        <Link href={`/product/${product.ProductID}`}>
                          <img style={{ borderRadius: "6px", width: "100%", boxShadow: "5px 5px 5px 0px rgb(0 0 0 / 50%)" }}
                              src={`/images/${product.ImageURL}`} alt={product.Title} />
                        </Link>
                      </Box>
                      <CardContent>
                        <Typography variant="h7" component="div" textAlign="center" sx={{marginBottom: "10px"}}>{product.ShortTitle}</Typography>
                        <Typography variant="caption">{product.ShortText}</Typography>
                      </CardContent>
                      <CardActions sx={{ display: "block" }}>
                        <Button size="small" sx={{ float: "right" }} onClick={() => goToProductPage(product.ProductID)}>Learn More</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>))}
        </Box>
        <Footer />
      </Box>
    </Box>
  </HelmetProvider>);
}