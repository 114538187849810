import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Badge, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ShoppingCart } from "@mui/icons-material";
// import { AccountBox } from "@mui/icons-material";

import logoWhite from "../assets/logo-white.png";
// import "./header.scss";
import { getCart } from "./cartLogic";

const pages = [{text: 'Home', href: '/'}, {text: 'Products', href: '/products'}, {text: 'Help', href: '/help'}, {text: 'About', href: '/about'}, {text: 'View Cart', href: '/cart'}];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export default function Header(params) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [cart, setCart] = React.useState(getCart());
  const navigate = useNavigate();

  useEffect(() => {
    if (params.cart != null) {
      setCart(params.cart);
    }
  }, [setCart, params]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  // boxShadow: "5px 5px 10px 0px rgb(0 0 0 / 50%)", 

  return (<>
    <AppBar position="static" sx={{ boxShadow: "0 5px 10px 0 rgb(0 0 0 / 60%)", background: "linear-gradient(230deg, #4343a1, #232363)" }} >
      <Container sx={{ maxWidth: "1280px", padding: "0 20px !important" }} maxWidth="1280px" >
        <Toolbar disableGutters>
          <Link href="/"><img src={logoWhite} alt="Verity Publishing logo" height={50}/></Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({href, text}) => (
                <MenuItem key={text} onClick={handleCloseNavMenu}>
                    <Link sx={{ textDecoration: 'none', color: '#232363' }} href={href}>{text}</Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button key={page.text} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                <Link sx={{color: 'white'}} href={page.href}>{page.text}</Link>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {/* <Tooltip title="Profile">
              <IconButton sx={{ marginRight: "15px" }} onClick={handleOpenUserMenu}>
                <AccountBox size={32} color="secondary"/>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="View Shopping Cart">
              <Badge overlap="circular" color="whiteness" badgeContent={cart?.reduce((total, item) => { return total + item.quantity; }, 0) ?? 0}>
                <IconButton onClick={() => navigate('/cart')}>
                  <ShoppingCart size={32} color="secondary"/>
                </IconButton>
              </Badge>
            </Tooltip>
            {/* <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  </>);
}