import React, { useCallback, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Box, Button, Grid, Link, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Header from "../components/header";
import Footer from "../components/footer";
import StripeElement from "../components/stripeElement";
import { productList } from "../components/productList";
import { priceList } from "../components/priceList";
import { getCart } from "../components/cartLogic";
import { getOrderString, updateOrder } from "../components/orderLogic";

// Calling loadStripe() outside of a component’s render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe("pk_live_51LUgO1JrJmMlTBWQAY29rqWya5j8LyA57ejE9CLibQrK4m7PLVtGZXDwFoziU05fF3nwdenQpHb32wsWJUfNz3LA00i16NmNqt");

export default function Checkout() {
  const [addressInfo, setAddressInfo] = React.useState(window.localStorage.getItem("addressInfo") 
    ? JSON.parse(window.localStorage.getItem("addressInfo"))
    : {name: "", addressLine1: "", addressLine2: "", city: "", state: "", zip: "", email: "" });
  const {name, addressLine1, addressLine2, city, state: stateProvince, zip, email } = addressInfo;
  const [total, setTotal] = React.useState(0);
  const [tax, setTax] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);

  const [clientSecret, setClientSecret] = React.useState("");
  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#232363',
    },
  };
  const stripeOptions = {
    clientSecret,
    appearance,
  };

  let subTotal = 0;

  const showPaymentBox = () => {
    if (clientSecret !== "") return;
    setIsLoading(true);
    // Save the customer info and totals to the order
    updateOrder({
      "customer": {
        "name": name,
        "email": email,
        "addressLine1": addressLine1,
        "addressLine2": addressLine2,
        "city": city,
        "state": stateProvince,
        "zip": zip
      },
      "subtotal": subTotal,
      "tax": tax,
      "sandh": 0,
      "total": total,
      "items": getCart(),
      "status": "submitted"
    });

    // Create the Stripe PaymentIntent with the order info
    fetch("https://7xy9woipl7.execute-api.us-west-2.amazonaws.com/prod/payment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: getOrderString(),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }

  const calcTaxAndTotal = useCallback((theStateProvince) => {
    // We only charge tax on Utah orders since we are based in Utah 
    //   and other states don't reach the minimal level to pay taxes
    const tempTax = (theStateProvince.toLowerCase() === 'utah' || theStateProvince.toLowerCase() === 'ut') ? Math.ceil(subTotal * 0.0875) : 0;
    setTax(tempTax);
    setTotal(subTotal + tempTax);
  }, [subTotal]);

  useEffect(() => {
    window.localStorage.setItem("addressInfo", JSON.stringify(addressInfo));
    calcTaxAndTotal(stateProvince);
  }, [addressInfo, calcTaxAndTotal, stateProvince])

  const addressStateChanged = (event) => {
    setAddressInfo({...addressInfo, state: event.currentTarget.value});
    calcTaxAndTotal(event.currentTarget.value); // If state is already there, calc the tax
  }

  const disableNextButton = () => {
    if (isLoading) return true;
    if ((name ?? "") === "") return true;
    if ((addressLine1 ?? "") === "") return true;
    if ((city ?? "") === "") return true;
    if ((stateProvince ?? "") === "") return true;
    if ((zip ?? "") === "") return true;
    if ((email ?? "") === "") return true;
    return false;
  }

  return (<HelmetProvider>
    <Helmet title="Checkout"/>
    <Header />
    <Box sx={{ padding: "0 16px", display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "1280px", width: "100%" }}>
        <h1>Checkout</h1>
        <Grid container spacing={3} sx={{ marginBottom: "50px" }}>
          <Grid item xs={12} sm={7} >
            <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px", display:"flex", flexDirection: "column", marginBottom: "25px" }}>
              <Typography variant="h5">Delivery Address</Typography>
              <Typography sx={{ margin: "10px 0 0 10px"}} variant="caption">* Please note: we can only ship to addresses in the United States</Typography>
              <TextField sx={{ marginTop: "10px"}} required={true} label="Name" value={name} onChange={(event) => setAddressInfo({...addressInfo, name: event.currentTarget.value})} />
              <TextField sx={{ marginTop: "10px"}} required={true} label="Address Line 1" value={addressLine1} onChange={(event) => setAddressInfo({...addressInfo, addressLine1: event.currentTarget.value})} />
              <TextField sx={{ marginTop: "10px"}} label="Address Line 2" value={addressLine2} onChange={(event) => setAddressInfo({...addressInfo, addressLine2: event.currentTarget.value})} />
              <Box sx={{ flexDirection: "row", marginTop: "10px" }}>
                <TextField sx={{ width: "50%" }} required={true} label="City" value={city} onChange={(event) => setAddressInfo({...addressInfo, city: event.currentTarget.value})} />
                <TextField sx={{ width: "20%", marginLeft: "10px"}} required={true} label="State" value={stateProvince} onChange={addressStateChanged} />
                <TextField sx={{ width: "20%", marginLeft: "10px"}} required={true} label="Zip" value={zip} onChange={(event) => setAddressInfo({...addressInfo, zip: event.currentTarget.value})} />
              </Box>
              <TextField sx={{ marginTop: "10px"}} label="Email Address *" value={email} onChange={(event) => setAddressInfo({...addressInfo, email: event.currentTarget.value})} />
              {/* <Typography sx={{ marginTop: "10px"}} variant="caption">* So we can email you about your order</Typography> */}
              <Box sx={{ display: "block", width: "100%" }}>
                <Button sx={{ float: "right", margin: "20px 0 0 20px", width: "140px" }} variant="contained" onClick={showPaymentBox} disabled={disableNextButton()}>Next</Button>
              </Box>
            </Box>
            {clientSecret && <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px" }}>
              <Typography variant="h5">Payment Information</Typography>
              <Elements options={stripeOptions} stripe={stripePromise} key={clientSecret}>
                <StripeElement />
              </Elements>
            </Box>}
          </Grid>
          <Grid item xs={12} sm={5} >
            <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px" }}>
              {/* <Typography variant="h5" sx={{ paddingBottom: "15px" }}>Order contents</Typography> */}
              <Table>
                <TableBody>
                {getCart().map((item, index) => {
                  const price = priceList.filter(p => p.ProductID === item.productId && (p.FormatType === 1 || p.FormatType === 2))[0];
                  const amount = item.quantity * price?.OurPrice;
                  subTotal += amount;
                  const product = productList.filter(p => p.ProductID === item.productId)[0];

                  return <TableRow key={index}>
                      <TableCell sx={{ fontWeight: "bold", fontSize: "1.2em", padding: "10px" }}>{item.quantity}</TableCell>
                    {/* <Avatar sx={{ float: "right", top: "-15px", right: "5px", boxShadow: "0px 0px 25px 15px rgba(255 255 255 0.6) !important" }} src={"/images/" + authorList.filter(author => author.AuthorID === product.Author1ID)[0].PortraitURL} /> */}
                      <TableCell sx={{ fontSize: "1.0em", padding: "10px 10px 10px 5px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <img style={{ borderRadius: "6px", height: "50px", boxShadow: "3px 3px 3px 0px rgb(0 0 0 / 50%)" }}
                             src={`/images/${product.ImageURL}`} alt={product.Title} />
                        <Link sx={{ paddingLeft: "12px", textDecoration: "none" }} href={`/product/${product.productId}`}>{product.ShortTitle}</Link>
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>{(amount / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</TableCell>
                    </TableRow>;
                  })}
                  <TableRow>
                    <TableCell align="right" colSpan={2} sx={{ fontSize: "1.0em", padding: "10px"}}>Subtotal</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>{(subTotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" colSpan={2} sx={{ fontSize: "1.0em", padding: "10px"}}>Shipping &amp; Handling</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>$0.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" colSpan={2} sx={{ fontSize: "1.0em", padding: "10px"}}>Taxes (Utah addresses only)</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>{(tax / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" colSpan={2} sx={{ fontSize: "1.0em", padding: "10px"}}>Total</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>{(total / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}