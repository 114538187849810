
export function getOrderString() {
  return window.localStorage.order || '{}';
}

export function getOrder() {
  return JSON.parse(window.localStorage.order || '{}');
}

export function clearOrder() {
  window.localStorage.order = '{}';
}

export function updateOrder(add) {
  let order = JSON.parse(window.localStorage.order || '{}');
  order = {...order, ...add};
  window.localStorage.order = JSON.stringify(order);
}