
// The cart consists of an array of items, which contains:
// { itemId: x, productId: xx, quantity: x, unitPrice: x.xx }
//  -- itemId is an incrementing key so that correct item can be editted/deleted
//  -- productId is the item's product ID
//  -- quantity  is the quantity of product items needed
//  -- unitPrice is the price of the item (OurPrice from the price list)

import { priceList } from "./priceList";

export function getCart() {
  return JSON.parse(window.localStorage.cart || '[]');
}

export function addToCart(productId) {
  let cart = JSON.parse(window.localStorage.cart || null);
  // Check if this item is already in the cart
  let item = cart?.filter(i => i.productId === productId);
  if (item && item.length > 0) {
    item[0].quantity++;
  } else {
    // The product is not in the cart so we'll add a new item of quantity 1
    let newKey = 1;
    if (cart) {
      // Get the max itemId + 1
      let ids = cart.map(c => { return c.itemId });
      newKey = Math.max(...ids) + 1;
    } else {
      cart = [];
    }
    const price = priceList.filter(p => p.ProductID === productId && (p.FormatType === 1 || p.FormatType === 2))[0];

    // Add the new item to the cart
    cart.push({itemId: newKey, productId: productId, quantity: 1, unitPrice: price.OurPrice });
  }
  window.localStorage.cart = JSON.stringify(cart);
  return cart;
}

export function changeQuantity(itemId, quantity) {
  let cart = JSON.parse(window.localStorage.cart || null);
  let item = cart.filter(c => c.itemId === itemId)[0];
  item.quantity = quantity;
  window.localStorage.cart = JSON.stringify(cart);
  return cart;
}

export function deleteItemFromCart(itemId) {
  let cart = JSON.parse(window.localStorage.cart || null);
  cart = cart.filter(c => c.itemId !== itemId);
  window.localStorage.cart = JSON.stringify(cart);
  return cart;
}

export function clearCart() {
  window.localStorage.cart = '[]';
  return [];
}

