import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Box, Button, Card, CardActions, CardContent, FormControl, InputLabel, Grid, Select, Typography, Link } from "@mui/material";

import { categoryList, topicList } from "../components/categoryList";
import { productList } from "../components/productList";
import { addToCart, getCart } from "../components/cartLogic";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Products() {
  const [cart, setCart] = React.useState(getCart());
  const [filter, setFilter] = React.useState(0);

  const navigate = useNavigate();

  const goToProductPage = (id) => { 
    navigate(`/product/${id}`);
  }

  const cartAdd = (productId) => {
    setCart(addToCart(productId));
    navigate('/cart');
  }

  return (<HelmetProvider>
    <Helmet title="Products"/>
    <Header cart={cart} />
    <Box className="mainBox">
      <Box sx={{ maxWidth: "1280px", width: "100%" }}>
        <h1 style={{ marginLeft: "10px"}}>Products</h1>
        <FormControl sx={{ float: "right", minWidth: "120px", marginTop: "-10px" }}>
          <InputLabel htmlFor="grouped-native-select">Filter by</InputLabel>
          <Select value={filter} native id="grouped-native-select" label="Filter by" onChange={(event) => setFilter(parseInt(event.target.value))}>
            <option aria-label="None" value={0} />
            {topicList.map((topic) => (
              <optgroup label={topic}>
                {categoryList.filter(c => c.Topic === topic).map((category) => (
                  <option value={category.CategoryID}>{category.Name}</option>
                ))}
              </optgroup>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={7}>
          {productList.filter(p => p.Hidden === false && p.CategoryIDs.includes(filter))
          .sort((a, b) => a.Popularity < b.Popularity ? -1 : 1)
          .map((product) => (
            <Grid item xs={12} sm={3} >
              <Card elevation={8} sx={{ marginTop: "30px", overflow: "visible" }}>
                <Box sx={{ margin: "-30px 15px 0 15px" }}>
                  <Link href={`/product/${product.ProductID}`}>
                    {/* <Avatar sx={{ float: "right", top: "-15px", right: "5px", boxShadow: "0px 0px 25px 15px rgba(255 255 255 0.6) !important" }} src={"/images/" + authorList.filter(author => author.AuthorID === product.Author1ID)[0].PortraitURL} /> */}
                    <img style={{ marginTop: "-30px", borderRadius: "6px", width: "100%", boxShadow: "5px 5px 5px 0px rgb(0 0 0 / 50%)" }}
                        src={`/images/${product.ImageURL}`} alt={product.Title} />
                  </Link>
                </Box>
                <CardContent>
                  <Typography variant="h6" component="div" textAlign="center">{product.Title}</Typography>
                  <Typography variant="body2">{product.ShortText}</Typography>
                </CardContent>
                <CardActions sx={{ display: "block" }}>
                  <Button size="small" onClick={() => cartAdd(product.ProductID)}>Add to Cart</Button>
                  <Button size="small" sx={{ float: "right" }} onClick={() => goToProductPage(product.ProductID)}>Learn More</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}