export const topicList = [ "Religious", "Political", "Collection"];

export const categoryList =
[
    {
      "CategoryID": 1,
      "Topic": "Religious",
      "Name": "Latter-Day Prophecies",
      "Priority": 3,
      "Hidden": false
    },
    {
      "CategoryID": 2,
      "Topic": "Religious",
      "Name": "Creation & Old Testament",
      "Priority": 2,
      "Hidden": false
    },
    {
      "CategoryID": 3,
      "Topic": "Religious",
      "Name": "Jesus Christ",
      "Priority": 1,
      "Hidden": false
    },
    {
      "CategoryID": 4,
      "Topic": "Religious",
      "Name": "Book of Mormon",
      "Priority": 4,
      "Hidden": false
    },
    {
      "CategoryID": 5,
      "Topic": "Religious",
      "Name": "Church History",
      "Priority": 5,
      "Hidden": false
    },
    {
      "CategoryID": 6,
      "Topic": "Religious",
      "Name": "Religious Speeches",
      "Priority": 6,
      "Hidden": false
    },
    {
      "CategoryID": 7,
      "Topic": "Political",
      "Name": "U.S. History / Constitution",
      "Priority": 7,
      "Hidden": false
    },
    {
      "CategoryID": 8,
      "Topic": "Political",
      "Name": "The Fight for Freedom",
      "Priority": 8,
      "Hidden": false
    },
    {
      "CategoryID": 9,
      "Topic": "Political",
      "Name": "Constitutional Seminars",
      "Priority": 9,
      "Hidden": true
    },
    {
      "CategoryID": 10,
      "Topic": "Political",
      "Name": "Child Development",
      "Priority": 10,
      "Hidden": false
    },
    {
      "CategoryID": 11,
      "Topic": "Political",
      "Name": "Law Enforcement",
      "Priority": 11,
      "Hidden": true
    },
    {
      "CategoryID": 12,
      "Topic": "Political",
      "Name": "Political Speeches",
      "Priority": 12,
      "Hidden": true
    },
    {
      "CategoryID": 13,
      "Topic": "Collection",
      "Name": "Collected Works",
      "Priority": 13,
      "Hidden": false
    }
];