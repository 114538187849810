import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../components/footer";
import Header from "../components/header";

export default function About() {
  return (<HelmetProvider>
    <Helmet title="About Us"/>
    <Header />
    <Box className="mainBox">
      <Box className="subMainBox">
        <h1>Our Authors</h1>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Card elevation={8} sx={{ overflow: "visible" }}>
              <CardContent>
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <img style={{ width: "200px", paddingTop: "20px" }} src="../images/wcs.jpg" alt="W. Cleon Skousen" />
                  <h2>W. Cleon Skousen</h2>
                </Box>
                <Typography>W. Cleon Skousen, the second of nine children, was born in Raymond, Alberta, Canada, in 1913. He moved to California with his parents at the age of ten.</Typography>
                <Typography>He began a careful study of the scriptures at the age of 17 when he was called on a mission for the Church of Jesus Christ of Latter-day Saints to serve in England, and had a well-known scriptural scholar, Apostle John A. Widstoe, as his first mentor.</Typography>
                <Typography>He subsequently graduated from George Washinton University with a Juris Doctorate degree. After law school, he spent sixteen years with the FBI, four years as Chief of Police in Salt Lake City, and then served for ten years as a full professor at Brigham Young University in the Department of Religion.</Typography>
                <Typography>He has written more than a dozen books on scriptural subjects and considerably more on various secular themes including Constitutional principles, law enforcement and child development.</Typography>
                <Typography>He has conducted many tours to the Holy Land and has lectured extensively throughout the United States and in 45 different countries.</Typography>
                <Typography>He and his wife, Jewel, are the parents of eight children and fifty grandchildren. He passed away on January 13, 2006, just 11 days shy of his ninety-third birthday.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card elevation={8} sx={{ overflow: "visible" }}>
              <CardContent>
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <img style={{ width: "200px", paddingTop: "20px" }} src="../images/ens.jpg" alt="Eric N. Skousen" />
                  <h2>Eric N. Skousen</h2>
                </Box>
                <Typography>Eric N. Skousen was born in 1941 in Washington, D.C., and educated in California and Utah. He graduated from Brigham Young University with of a Bachelor of Science and Masters of Arts degree in Physics, and a Ph.D. in Mechanical Engineering Science.</Typography>
                <Typography>In addition to an extensive university teaching career and membership in scientific honor societies, Dr. Skousen has worked as an engineering scientist in the fields of energy systems, fluid dynamics and space physics.</Typography>
                <Typography>He is the author of several popular books on energy policy and nuclear power. Widely traveled, he has lectured in the United States, South America, Europe and Middle East on gospel themes and his scientific pursuits.</Typography>
                <Typography>He and his wife, Cheryl, are the parents of eight children.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card elevation={8} sx={{ overflow: "visible" }}>
              <CardContent>
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <img style={{ width: "200px", paddingTop: "20px" }} src="../images/rns.jpg" alt="Richard N. Skousen" />
                  <h2>Richard N. Skousen</h2>
                </Box>
                <Typography>Richard N. Skousen, a best-selling author, began his career as a software developer after serving a mission in Brazil.</Typography>
                <Typography>With a sincere interest in the lives of latter-day prophets, he began an in-depth study of the life of the Prophet Joseph Smith. This study culminated in the publication of the two-volume work, <i>Brother Joseph—Seer of a New Dispensation</i>.</Typography>
                <Typography>After 25 years of research, he published <i>His Return—Prophecy, Destiny &amp; Hope</i>, which discusses the events associated with the Millennium and our Savior’s Second Coming.</Typography>
                <Typography>While researching this book, he noticed that more needed to be said about the future rise of the political Kingdom of God. As a result, he published <i>Hanging by a Thread—The Rebirth of our Dying Constitution</i>.</Typography>
                <Typography>He recently completed the ground-breaking study of our Savior by publishing <i>Discovering Christ—Developing the Attributes of our Savior Jesus Christ</i>.</Typography>
                <Typography>He and his wife, Jill, live in Pleasant Grove, Utah. They are the parents of four children.</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <h1>Our Company</h1>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px" }}>
          <Typography>Verity Publishing, Inc. was founded in April of 1993. After securing permission from Dr. W. Cleon Skousen, we began to optically scan his writings and transcribe his speeches. Within a year we began the distribution of The Religious Works of W. Cleon Skousen. The Political Works of W. Cleon Skousen were completed in September of 1994.</Typography>
          <Typography>Since 1995 we have published eleven hard-bound books: So You Want to Raise a Boy? (1995), The Majesty of God's Law (1996), Earth—In the Beginning (1997), The First 2,000 Years (1998), The Third Thousand Years (1998), The Fourth Thousand Years (1998), Gospel Diamond Dust (1998), Holding On (2004), Brother Joseph, volume one (2004), Brother Joseph, volume two (2005), His Return—Prophecy, Destiny and Hope (2007) and Hanging by a Thread—The Rebirth of Our Dying Constitution (2016).</Typography>
          <Typography>We have continued to upgrade and improve The Works of W. Cleon Skousen. In 1998, version 2.0 of The Works of W. Cleon Skousen was released, which included the illustrations from the original books, as well as several additional works. Version 3.0 of The Works of W. Cleon Skousen was released in 2008, which supported all versions of operating systems, including Windows, Macintosh and Linux.</Typography>
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}