import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Header from "../components/header";
import Footer from "../components/footer";
import { Box, Typography } from "@mui/material";
import { clearCart, getCart } from "../components/cartLogic";
import { getOrderString, updateOrder, clearOrder } from "../components/orderLogic";

export default function Complete() {
  const [cart, setCart] = React.useState(getCart());
  const [orderSaved, setOrderSaved] = React.useState(false);
  const [orderNumber, setOrderNumber] = React.useState("");

  useEffect(() => {
    async function saveOrder() {
      setCart(clearCart());
      
      setOrderSaved(true);

      // Mark the order as paid
      updateOrder({ "status": "paid" });

      // Call the lambda to save the order
      await fetch("https://7xy9woipl7.execute-api.us-west-2.amazonaws.com/prod/saveOrder", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: getOrderString(),
      })
        .then((res) => res.json())
        .then((data) => setOrderNumber(data.orderId));
  
      clearOrder();
    }
    
    if (!orderSaved && getCart().length > 0) {
      saveOrder();
    }
  }, [orderSaved]);
  
  return (<HelmetProvider>
    <Helmet title="Order Complete"/>
    <Header cart={cart} />
    <Box className="mainBox">
      <Box className="subMainBox">
        <h1>Order Complete</h1>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px" }}>
          <Typography>Thank you for your order.</Typography>
          <Typography>Your order number is <span style={{ fontSize: "1.2em", fontWeight: "bold"}}>{orderNumber}</span>.</Typography>
          <Typography>We have sent you an order confirmation email.</Typography>
          <Typography>We will send you another email when your order ships.</Typography>
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}