import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Box, Button, Grid, Link, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { productList } from "../components/productList";
import { priceList } from "../components/priceList";
import { getCart, changeQuantity, deleteItemFromCart } from "../components/cartLogic";
import Header from "../components/header";
import Footer from "../components/footer";

import "./cart.scss";

export default function Cart() {
  const [cart, setCart] = React.useState(getCart());
  const [subTotal, setSubTotal] = React.useState(0);
  const navigate = useNavigate();

  const calcSubTotal = useCallback((cart) => {
    let subTotal = 0;
    cart.forEach((item) => {
      const price = priceList.filter(p => p.ProductID === item.productId && (p.FormatType === 1 || p.FormatType === 2))[0];
      const amount = item.quantity * price?.OurPrice;
      subTotal += amount;
    });
    setSubTotal(subTotal);
  }, [setSubTotal]);

  useEffect(() => {
    calcSubTotal(cart);
  }, [cart, calcSubTotal]);

  const newQuantity = (itemId, qty) => {
    let newCart = changeQuantity(itemId, parseInt(qty));
    // setCartQty(newCart?.reduce((total, item) => { return total + item.quantity; }, 0) ?? 0);
    setCart(newCart);
    calcSubTotal(newCart);
  }

  const deleteItem = (itemId) => {
    let newCart = deleteItemFromCart(itemId);
    // setCartQty(newCart?.reduce((total, item) => { return total + item.quantity; }, 0) ?? 0);
    setCart(newCart);
    calcSubTotal(newCart);
  }

  return (<HelmetProvider>
    <Helmet title="Shopping Cart"/>
    <Header cart={cart} />
    <Box className="mainBox">
      <Box className="subMainBox">
        <h1>Shopping Cart</h1>
        {/* <Typography><span style={{ color: "red" }}>Please note:</span> our offices will be closed until May 19, 2023. We will be unable to fulfill orders until that time.</Typography> */}
        <Grid container>
          <Grid className="cartLeftGrid" item xs={12} md sx={{ borderRadius: "6px", backgroundColor: "white" }}>
            {(cart?.length ?? 0) === 0 && <Box sx={{ padding: "30px"}} >The cart is currently empty.</Box>}
            {cart?.map((item, key) => {
              const product = productList.filter(p => p.ProductID === item.productId)[0];
              const price = priceList.filter(p => p.ProductID === item.productId && (p.FormatType === 1 || p.FormatType === 2))[0];

              return <Grid key={key} container>
                <Grid item xs="auto" md="auto" sx={{ alignSelf: "center", padding: "10px", textAlign: "center" }}>
                  <Link href={`/product/${product.ProductID}`}>
                    <img style={{ borderRadius: "6px", height: "150px", boxShadow: "5px 5px 5px 0px rgb(0 0 0 / 50%)" }}
                          src={`/images/${product.ImageURL}`} alt={product.Title} />
                  </Link>
                </Grid>
                <Grid xs md item>
                  <Grid container>
                    <Grid item xs md={10} sx={{ alignSelf: "center", padding: "10px" }}>
                      <Link href={`/product/${product.ProductID}`} sx={{ textDecoration: "none" }}>
                        <h4 style={{marginBottom: "5px"}}>{product.Title}</h4>
                      </Link>
                    </Grid>
                    <Grid item xs="auto" md={2} sx={{ alignSelf: "center", padding: "10px", marginTop: "20px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>{(price.OurPrice / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ padding: "0 10px 20px 30px" }} >
                      <Box sx={{ display: "flex" }}>
                          <Button sx={{ border: "solid 1px #232363", padding: "0", minWidth: "40px" }} disabled={item.quantity <= 1} onClick={() => newQuantity(item.itemId, item.quantity - 1)}>
                            <RemoveIcon />
                          </Button>
                          <Typography sx={{ padding: "2px 20px", border: "solid 1px #232363", borderRadius: "4px", fontWeight: "bold" }}>{item.quantity}</Typography>
                          <Button sx={{ border: "solid 1px #232363", padding: "0px", minWidth: "40px" }} onClick={() => newQuantity(item.itemId, item.quantity + 1)}>
                            <AddIcon />
                          </Button>
                        <Button sx={{ margin: "1px 0 0 10px", fontSize: "11px" }} onClick={() => deleteItem(item.itemId)}>Remove</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            })}
          </Grid>
          <Grid className="cartRightGrid" item xs={12} md="auto">
            <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>Subtotal</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>{(subTotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>Shipping &amp; Handling</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>$0.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>Total</TableCell>
                    <TableCell align="right" sx={{ fontSize: "1.0em", padding: "10px"}}>{(subTotal / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{ marginBottom: "20px", backgroundColor: "white" }}>
        </Grid>
        <Box>
          <Button variant="contained" startIcon={<NavigateBeforeIcon sx={{marginRight: "-8px"}} />} onClick={() => navigate('/products')}>Continue Shopping</Button>
          <Button disabled={(cart?.length ?? 0) === 0} sx={{ float: "right" }} variant="contained" endIcon={<NavigateNextIcon sx={{marginLeft: "-8px"}} />} onClick={() => navigate('/checkout')}>Check Out</Button>
          {/* <Button disabled={true}                  sx={{ float: "right" }} variant="contained" endIcon={<NavigateNextIcon sx={{marginLeft: "-8px"}} />} onClick={() => navigate('/checkout')}>Check Out</Button> */}
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}
