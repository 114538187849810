import React, { useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Box, Button, FormControl, InputLabel, Link, Select, TextField, Typography } from "@mui/material";
import Footer from "../components/footer";
import Header from "../components/header";
import TopBar from "./wcstop.jpg"

export default function Search() {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filter, setFilter] = React.useState(0);
  const [results, setResults] = React.useState({});
  const [pageLinks, setPageLinks] = React.useState([]);
  const [searchParams] = useSearchParams();

  const doSearch = useCallback((search, page, filter) => {
    async function executeSearch(search, page, filter) {
      setIsLoading(true);
      let body = {
        "search": search,
        "page": page,
        "selectedIndex": filter
      };
      try {
        await fetch("https://7xy9woipl7.execute-api.us-west-2.amazonaws.com/prod/search", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        })
        .then((res) => res.json())
        .then((data) => setTheResultData(data, search, page, filter));
      }
      catch (err) {
        alert("An error occurred while searching: " + err);
      }
      setIsLoading(false);
    }
    executeSearch(search, page, filter);

    const setTheResultData = (data, search, page, filter) => {
      if (!data.results || data.results.length === 0)
        return;
  
      setResults(data);
  
      // Put the page links in an array to display below
      let linkList = [];
  
      // Put a previous link if we are on page 2 or greater
      if (page > 1)
        linkList.push(GetLinkData("<< Prev", search, page - 1, filter));
  
      let end = 10;
      let start = page - 5;
      if (start < 1)
        start = 1;
      let i = start;
      for (let j = 0; i < (data.total / 10) + 1 && j < end; i++, j++) {
        if (i === page)
          linkList.push({ text: i.toString(), url: "-1" });
        else
          linkList.push(GetLinkData(i.toString(), search, i, filter));
      }
      // Put the Next page link (if we are not showing the last page)
      if (i - 1 !== page)
        linkList.push(GetLinkData("Next >>", search, page + 1, filter));
  
      setPageLinks(linkList);
    }
 
    const GetLinkData = (text, search, page, filter) => {
      return { 
        text: text, 
        url: "search.aspx?searchText=" + encodeURIComponent(search) + "&page=" + page + "&filter=" + filter
      };
    }
  }, [setIsLoading, setPageLinks, setResults]);

  // Read the URL and set the search string, page number and filter (if they are there in the URL)
  useEffect(() => {
    if (!initialLoad)
      return;
    setInitialLoad(false);

    let filter = searchParams.get("filter");
    if (filter) {
      filter = parseInt(filter);
      setFilter(filter);
    }

    let page = searchParams.get("page");
    page = page ? parseInt(page) : 1;

    let search = searchParams.get("searchText");
    if (search)
      setSearchText(decodeURIComponent(search));
    else
      return;

    doSearch(search, page, filter);
  }, [setSearchText, setFilter, doSearch, initialLoad, searchParams]);

  const handleSearchEnterKey = (event) => {
    if (event.keyCode === 13)
      doSearch(searchText, 1, filter);
  }

  return (<HelmetProvider>
    <Helmet title="Search the Works of W. Cleon Skousen"/>
    {isLoading && <Box className="inProgress"><Box className="spinner"></Box></Box>}
    <Header />
    <Box className="mainBox">
      <Box className="subMainBox">
        <img src={TopBar} alt="Works of W. Cleon Skousen header" width="100%" style={{ maxWidth: "1280px"}} />
        <h1>Search</h1>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px", marginBottom: "50px" }}>
          <Typography>Enter one or more words to search <i>The Works of W. Cleon Skousen</i>, 
          <i>The Works of Eric N. Skousen</i> or <i>The Works of Richard N. Skousen</i>.</Typography>
          <Typography>You can also use the Category drop-down menu to narrow your search.</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", padding: "30px 15px" }}>
            <TextField sx={{ }} label="Search word(s)" value={searchText} onChange={(event) => setSearchText(event.currentTarget.value )} onKeyDown={handleSearchEnterKey} />
            <FormControl sx={{ float: "right", minWidth: "120px", padding: "0 15px" }}>
              <InputLabel>&nbsp;&nbsp;&nbsp;&nbsp;Filter by</InputLabel>
              <Select value={filter} native label="Filter by" onChange={(event) => setFilter(parseInt(event.target.value))}>
                <option value={0}>-- Search all subjects --</option>
                <option value={1}>Old Testament</option>
                <option value={2}>New Testament</option>
                <option value={3}>Book of Mormon</option>
                <option value={4}>Church history / D&C</option>
                <option value={5}>Latter-day prophecy</option>
                <option value={6}>Religious speeches</option>
                <option value={7}>Religious essays & short books</option>
                <option value={8}>U.S. history & Constitution</option>
                <option value={9}>Freedom fight</option>
                <option value={10}>Constitutional seminars</option>
                <option value={11}>Child development</option>
                <option value={12}>Law enforcement</option>
                <option value={13}>Political speeches & articles</option>
                <option value={14}>Works of Eric N. Skousen</option>
                <option value={15}>Works of Richard N. Skousen</option>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={() => doSearch(searchText, 1, filter)}>Search</Button>
          </Box>
          {results?.message && <Box sx={{ padding: "20px"}}>
            {results.results && results.results.map((result, i) => (
              <Box key={i}>
                <Link variant="h6" href={result.url}>{result.title}</Link>
                <div dangerouslySetInnerHTML={{__html: result.text}} style={{ padding: "0 0 15px 15px" }} />
              </Box>
            ))}
            {results.skipped && 
              <Typography variant="caption" sx={{ display: "block", textAlign: "center" }}>
                <b>Note:</b> The following word(s) were not included in the search: <b>{results.skipped}</b>
              </Typography>
            }
            <Box sx={{ justifyContent: "center", display: "flex", flexDirection: "row", padding: "20px" }}>
              {pageLinks.map((linkInfo, i) => 
                {return linkInfo.url !== "-1"
                ? <Link variant="h6" sx={{ padding: "0 12px" }} key={i} href={linkInfo.url}>{linkInfo.text}</Link> 
                : <Typography variant="h6" sx={{ padding: "0 12px" }} key={i}>{linkInfo.text}</Typography>
                }
              )}
            </Box>
            <h3 style={{ textAlign: "center" }}>{results.message}</h3>
          </Box> }
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}

