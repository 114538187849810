import React, { useEffect } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

export default function StripeElement() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment successful");
          break;
        case "processing":
          setMessage("Your payment is processing...");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again");
          break;
        default:
          setMessage("Something went wrong, please try again");
          break;
      }
    });
  }, [stripe]);
  
  const submitOrder = async () => {
/*    // Check that we have all the needed fields, and notify the user of any missing fields
    if (params.name === "") {
      alert("Please enter a name");
      return;
    }
    if (params.addressLine1 === "") {
      alert("Address Line is missing");
      return;
    }
    if (params.city === "") {
      alert("City is missing");
      return;
    }
    if (params.state === "") {
      alert("State is missing");
      return;
    }
    if (params.zip === "") {
      alert("Zip is missing");
      return;
    }
    if (params.email === "") {
      alert("Email is blank");
      return;
    }
*/
    if (!stripe || !elements)
      return;
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Redirect when successful to the payment completion page
        return_url: window.location.origin + "/complete",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, the customer is redirected to the complete page.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred, please try again");
    }

    setIsLoading(false);
  }
    
  return (<Box sx={{ padding: "20px 0 30px 0" }} >
    <PaymentElement />
    <Typography sx={{ color: "red", paddingTop: "10px" }}>{message}</Typography>
    <Button sx={{ float: "right", margin: "0 0 20px 20px", width: "140px" }} variant="contained"
            onClick={submitOrder} disabled={isLoading || !stripe || !elements}>
      {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Place Order"}
    </Button>
  </Box>);
}
