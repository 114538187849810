import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Box, Button, Grid, Typography } from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { productList } from "../components/productList";
import { priceList } from "../components/priceList";
import { addToCart, getCart } from "../components/cartLogic";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Product() {
  const [cart, setCart] = React.useState(getCart());

  const navigate = useNavigate();
  const params = useParams();
  const productId = Number(params.id);

  useEffect(() => {
    if (isNaN(productId)) {   // Go to the home page if we got a non-number
      navigate('/');
      return;
    }
    if (productId > productList[productList.length - 1].ProductID) {    // Ditto if the product is out of range
      navigate('/');
      return;
    }
    const product = productList.filter(p => p.ProductID === productId)[0];
    if (!product) {
      navigate('/');     // Ditto if this is an unknown product
      return;
    }
  });

  const product = productList.filter(p => p.ProductID === productId)[0];
  if (!product)
    return;     // No need to proceed if we got an invalid or unknown product ID
  const price = priceList.filter(p => p.ProductID === product.ProductID && (p.FormatType === 1 || p.FormatType === 2))[0];

  // Get the subtitle from the title (if there is one)
  let title = product.Title;
  let subTitle = '';
  let pos = title.indexOf('—');
  if (pos !== -1) {
    subTitle = title.substring(pos + 1);
    title = title.substring(0, pos);
  }

  const getHtml = (line) => {
    return {__html: line};
  }

  const cartAdd = () => {
    setCart(addToCart(parseInt(params.id)));
    navigate('/products');
  }

  return (<HelmetProvider>
    <Helmet title={title}/>
    <Header cart={cart}/>
    <Box className="mainBox" sx={{ paddingTop: "40px" }}>
      <Box className="subMainBox" sx={{ zIndex: 11 }}>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px", zIndex: 10 }}>
          <Grid container spacing={7}>
            <Grid item xs={12} sm={4} >
              <img style={{ marginTop: "24px", borderRadius: "6px", width: "100%", boxShadow: "5px 5px 5px 0px rgb(0 0 0 / 50%)" }}
                            src={`/images/${product.ImageURL}`} alt={product.Title} />
            </Grid>
            <Grid item xs={12} sm={8} >
              <h1>{title}</h1>
              <h2>{subTitle}</h2>
              <div dangerouslySetInnerHTML={getHtml(product.LongText)} />
              <Box sx={{ paddingTop: "20px" }}>
                <Button sx={{ float: "right" }} variant="contained" endIcon={<ShoppingCartIcon />} onClick={cartAdd}>Add to Cart</Button>
                {price && <Box>
                  <Typography variant="h5">{(price.OurPrice / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</Typography>
                  <Typography variant="h7" sx={{ textDecorationLine: "line-through", paddingLeft: "15px" }}>{(price.NormalPrice / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</Typography>
                </Box>}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}