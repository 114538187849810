import { Box, Typography } from "@mui/material";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../components/footer";
import Header from "../components/header";

export default function Error404() {
  return (<HelmetProvider>
    <Helmet title="Page not found"/>
    <Header />
    <Box className="mainBox">
      <Box className="subMainBox">
        <h1>Resource not found</h1>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px" }}>
          <Typography sx={{ padding: "100px 0" }}>We were unable to find the resource you are seeking.</Typography>
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}