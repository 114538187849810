import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import Footer from "../components/footer";
import Header from "../components/header";

export default function Contact({feedback}) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comment, setComment] = React.useState("");
  const navigate = useNavigate();

  const subject = feedback ? "Verity Publishing - Feedback" : "Verity Publishing - Help Needed";
  const sendClick = async () => {
    let body = {
      "from": email,
      "to": "richskousen@gmail.com,support@veritypublishing.com",
      "subject": subject,
//      "text": `From: ${name}\n\nEmail: ${email}\n\nSubject: ${subject}\n\nComment: ${comment}\n`,
      "html": `<p>From: ${name}</p><p>Email: ${email}</p><p>Subject: ${subject}</p><p>Comment: ${comment}</p>`
    };
    try {
      await fetch("https://7xy9woipl7.execute-api.us-west-2.amazonaws.com/prod/email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });
      alert("Your message has been sent");
      navigate("/");
    }
    catch (err) {
      alert("An error occurred while sending: " + err);
    }
  }

  const title = feedback ? "Send Feedback" : "Help Assistance";
  return (<HelmetProvider>
    <Helmet title={title}/>
    <Header />
    <Box sx={{ boxShadow: "5px 5px 10px 0px rgb(0 0 0 / 50%)", padding: "10px 16px 0px 16px", display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "1280px", width: "100%" }}>
        <h1>{title}</h1>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "20px", marginBottom: "50px", display: "flex", flexDirection: "column" }}>
          <Typography sx={{ paddingBottom: "10px" }}>
            {feedback ? "We would love to hear from you about any feedback that you have concerning this site." :
            "We would like to help you with any issues you might have experienced while using this site."}
          </Typography>
          <Typography sx={{ padding: "10px 0" }}>Please fill out the following information and we will get back with you.</Typography>
          <TextField sx={{ margin: "15px 0", width: "250px" }} label="Name" value={name} onChange={ (e) => setName(e.target.value) } />
          <TextField sx={{ margin: "15px 0", width: "250px" }} id="email" label="Email address" value={email} onChange={ (e) => setEmail(e.target.value) } />
          <TextField multiline rows="6" sx={{ margin: "15px 0" }} label="Comments" value={comment} onChange={ (e) => setComment(e.target.value) } />
          <Box sx={{ paddingTop: "10px", width: "100%" }}>
            <Button variant="contained" disabled={comment === ""} onClick={sendClick}>Send</Button>
            <Button sx={{ float: "right" }} variant="outlined" onClick={() => navigate("/")}>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}