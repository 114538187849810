export const priceList = [
    {
      "PriceID": 1,
      "ProductID": 1,
      "FormatType": 1,
      "Height": 0.93,
      "Weight": 1.49,
      "NormalPrice": 2495,
      "OurPrice": 2295
    },
    {
      "PriceID": 3,
      "ProductID": 1,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 4,
      "ProductID": 2,
      "FormatType": 1,
      "Height": 1.43,
      "Weight": 1.76,
      "NormalPrice": 2695,
      "OurPrice": 2295
    },
    {
      "PriceID": 6,
      "ProductID": 2,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 7,
      "ProductID": 3,
      "FormatType": 1,
      "Height": 1.61,
      "Weight": 1.97,
      "NormalPrice": 2695,
      "OurPrice": 2295
    },
    {
      "PriceID": 9,
      "ProductID": 3,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 10,
      "ProductID": 4,
      "FormatType": 1,
      "Height": 3.04,
      "Weight": 3.73,
      "NormalPrice": 5390,
      "OurPrice": 3995
    },
    {
      "PriceID": 11,
      "ProductID": 5,
      "FormatType": 1,
      "Height": 1.07,
      "Weight": 1.54,
      "NormalPrice": 2795,
      "OurPrice": 2595
    },
    {
      "PriceID": 13,
      "ProductID": 5,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 14,
      "ProductID": 6,
      "FormatType": 1,
      "Height": 0.98,
      "Weight": 1.48,
      "NormalPrice": 2495,
      "OurPrice": 2295
    },
    {
      "PriceID": 16,
      "ProductID": 6,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 17,
      "ProductID": 7,
      "FormatType": 1,
      "Height": 1.393,
      "Weight": 2.03,
      "NormalPrice": 2695,
      "OurPrice": 2395
    },
    {
      "PriceID": 19,
      "ProductID": 7,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 20,
      "ProductID": 8,
      "FormatType": 1,
      "Height": 1.652,
      "Weight": 2.4,
      "NormalPrice": 3495,
      "OurPrice": 2495
    },
    {
      "PriceID": 22,
      "ProductID": 8,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 895,
      "OurPrice": 695
    },
    {
      "PriceID": 23,
      "ProductID": 9,
      "FormatType": 1,
      "Height": 5.1,
      "Weight": 7.45,
      "NormalPrice": 11480,
      "OurPrice": 8595
    },
    {
      "PriceID": 24,
      "ProductID": 10,
      "FormatType": 1,
      "Height": 4,
      "Weight": 5.91,
      "NormalPrice": 8685,
      "OurPrice": 6595
    },
    {
      "PriceID": 25,
      "ProductID": 11,
      "FormatType": 2,
      "Height": 0.29,
      "Weight": 0.32,
      "NormalPrice": 695,
      "OurPrice": 595
    },
    {
      "PriceID": 27,
      "ProductID": 11,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 495,
      "OurPrice": 295
    },
    {
      "PriceID": 28,
      "ProductID": 12,
      "FormatType": 1,
      "Height": 1.04,
      "Weight": 1.53,
      "NormalPrice": 1795,
      "OurPrice": 1595
    },
    {
      "PriceID": 30,
      "ProductID": 12,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 31,
      "ProductID": 13,
      "FormatType": 1,
      "Height": 2.28,
      "Weight": 1.88,
      "NormalPrice": 2195,
      "OurPrice": 795
    },
    {
      "PriceID": 33,
      "ProductID": 13,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 299,
      "OurPrice": 150
    },
    {
      "PriceID": 34,
      "ProductID": 14,
      "FormatType": 1,
      "Height": 1.06,
      "Weight": 1.48,
      "NormalPrice": 1495,
      "OurPrice": 995
    },
    {
      "PriceID": 36,
      "ProductID": 14,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 37,
      "ProductID": 15,
      "FormatType": 1,
      "Height": 1.97,
      "Weight": 2.03,
      "NormalPrice": 2695,
      "OurPrice": 2295
    },
    {
      "PriceID": 39,
      "ProductID": 15,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 41,
      "ProductID": 16,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 43,
      "ProductID": 17,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 45,
      "ProductID": 18,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 47,
      "ProductID": 19,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 795,
      "OurPrice": 595
    },
    {
      "PriceID": 49,
      "ProductID": 20,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 51,
      "ProductID": 21,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 53,
      "ProductID": 22,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 55,
      "ProductID": 23,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 57,
      "ProductID": 24,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 59,
      "ProductID": 25,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 895,
      "OurPrice": 695
    },
    {
      "PriceID": 61,
      "ProductID": 26,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 63,
      "ProductID": 27,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 65,
      "ProductID": 28,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 67,
      "ProductID": 29,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 69,
      "ProductID": 30,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 395,
      "OurPrice": 195
    },
    {
      "PriceID": 71,
      "ProductID": 31,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 73,
      "ProductID": 32,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 75,
      "ProductID": 33,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 77,
      "ProductID": 34,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 79,
      "ProductID": 35,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 81,
      "ProductID": 36,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 695,
      "OurPrice": 495
    },
    {
      "PriceID": 83,
      "ProductID": 37,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 85,
      "ProductID": 38,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 595,
      "OurPrice": 395
    },
    {
      "PriceID": 87,
      "ProductID": 39,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 99,
      "OurPrice": 50
    },
    {
      "PriceID": 89,
      "ProductID": 40,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 99,
      "OurPrice": 50
    },
    {
      "PriceID": 91,
      "ProductID": 41,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 295,
      "OurPrice": 150
    },
    {
      "PriceID": 93,
      "ProductID": 42,
      "FormatType": 5,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 295,
      "OurPrice": 150
    },
    {
      "PriceID": 94,
      "ProductID": 43,
      "FormatType": 3,
      "Height": 0.57,
      "Weight": 0.2,
      "NormalPrice": 3995,
      "OurPrice": 2995
    },
    {
      "PriceID": 95,
      "ProductID": 43,
      "FormatType": 7,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 3995,
      "OurPrice": 2495
    },
    {
      "PriceID": 96,
      "ProductID": 44,
      "FormatType": 1,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 3995,
      "OurPrice": 1500
    },
    {
      "PriceID": 97,
      "ProductID": 46,
      "FormatType": 1,
      "Height": 0.86,
      "Weight": 1.2,
      "NormalPrice": 2495,
      "OurPrice": 2295
    },
    {
      "PriceID": 98,
      "ProductID": 17,
      "FormatType": 2,
      "Height": 1,
      "Weight": 2.1,
      "NormalPrice": 2495,
      "OurPrice": 2395
    },
    {
      "PriceID": 99,
      "ProductID": 18,
      "FormatType": 1,
      "Height": 1.3,
      "Weight": 2,
      "NormalPrice": 2695,
      "OurPrice": 2595
    },
    {
      "PriceID": 100,
      "ProductID": 19,
      "FormatType": 1,
      "Height": 1.3,
      "Weight": 2,
      "NormalPrice": 2695,
      "OurPrice": 2595
    },
    {
      "PriceID": 101,
      "ProductID": 20,
      "FormatType": 2,
      "Height": 0.6,
      "Weight": 1,
      "NormalPrice": 1795,
      "OurPrice": 1695
    },
    {
      "PriceID": 102,
      "ProductID": 21,
      "FormatType": 2,
      "Height": 0.6,
      "Weight": 1,
      "NormalPrice": 1795,
      "OurPrice": 1695
    },
    {
      "PriceID": 103,
      "ProductID": 22,
      "FormatType": 2,
      "Height": 0.6,
      "Weight": 1,
      "NormalPrice": 1795,
      "OurPrice": 1695
    },
    {
      "PriceID": 104,
      "ProductID": 23,
      "FormatType": 2,
      "Height": 0.6,
      "Weight": 1,
      "NormalPrice": 1795,
      "OurPrice": 1695
    },
    {
      "PriceID": 105,
      "ProductID": 24,
      "FormatType": 2,
      "Height": 0.6,
      "Weight": 0.7,
      "NormalPrice": 1795,
      "OurPrice": 1595
    },
    {
      "PriceID": 106,
      "ProductID": 25,
      "FormatType": 1,
      "Height": 1.4,
      "Weight": 2.2,
      "NormalPrice": 2995,
      "OurPrice": 2795
    },
    {
      "PriceID": 107,
      "ProductID": 26,
      "FormatType": 2,
      "Height": 1.1,
      "Weight": 1.5,
      "NormalPrice": 1895,
      "OurPrice": 1695
    },
    {
      "PriceID": 108,
      "ProductID": 27,
      "FormatType": 2,
      "Height": 0.4,
      "Weight": 0.6,
      "NormalPrice": 1195,
      "OurPrice": 1095
    },
    {
      "PriceID": 109,
      "ProductID": 36,
      "FormatType": 1,
      "Height": 0.8,
      "Weight": 1.3,
      "NormalPrice": 1995,
      "OurPrice": 1895
    },
    {
      "PriceID": 110,
      "ProductID": 40,
      "FormatType": 2,
      "Height": 0.1,
      "Weight": 0.1,
      "NormalPrice": 295,
      "OurPrice": 295
    },
    {
      "PriceID": 111,
      "ProductID": 49,
      "FormatType": 1,
      "Height": 1.06,
      "Weight": 1.48,
      "NormalPrice": 1495,
      "OurPrice": 10
    },
    {
      "PriceID": 112,
      "ProductID": 39,
      "FormatType": 2,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 1195,
      "OurPrice": 995
    },
    {
      "PriceID": 113,
      "ProductID": 43,
      "FormatType": 1,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 3995,
      "OurPrice": 3495
    },
    {
      "PriceID": 114,
      "ProductID": 50,
      "FormatType": 1,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 2995,
      "OurPrice": 2795
    },
    {
      "PriceID": 115,
      "ProductID": 47,
      "FormatType": 2,
      "Height": 0,
      "Weight": 0,
      "NormalPrice": 2295,
      "OurPrice": 1995
    }
    // {    // This was used to pay the Barnes & Noble order number 20010 of 24 BRJOS1 at wholesale price
    //   "PriceID": 115,
    //   "ProductID": 616,
    //   "FormatType": 1,
    //   "Height": 0,
    //   "Weight": 0,
    //   "NormalPrice": 2995,
    //   "OurPrice": 1617,
    //   "Filename": ""
    // }
  ];