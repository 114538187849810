import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
//import Header from "../components/header";
//import { Box, Typography } from "@mui/material";
import { Typography } from "@mui/material";

export default function Resource() {
  const [firstTime, setFirstTime] = React.useState(true);
  const [html, setHtml] = React.useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!firstTime)
      return;
    setFirstTime(false);

    async function getResource() {
      let id = searchParams.get("id");
      let show = searchParams.get("show");
      if (!id || !show)
          return;

      // console.log("getting doc");
      let body = {
        "id": id,
        "show": show,
      };
      try {
        await fetch("https://7xy9woipl7.execute-api.us-west-2.amazonaws.com/prod/resource", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        })
        .then((res) => res.json())
        .then((data) => {
          setHtml(data);
        });
      }
      catch (err) {
        alert("An error occurred while retrieving the document: " + err);
      }
    }
    getResource();
  }, [firstTime, html, searchParams]);

  useEffect(() => {
    let para = searchParams.get("para");
    let t = document.getElementsByName(para)[0];
    if (t) {
      t.scrollIntoView({block: "center"});
      // let pos = 0;
      // if (t.offsetParent) {
      //   do {
      //     pos += t.offsetTop;
      //   } while ((t = t.offsetParent));
      // }
      // console.log(pos);
      // window.scrollTo(0, pos);
    }
  });

  let id = parseInt(atob(searchParams.get("id")));

  return (<>
    {/* <Header /> */}
    {/* <br /> */}
    {((id > 548 && id < 791) || (id > 856 && id < 999)) && 
      <Typography style={{ textAlign: "center" }} variant="h6">Note: For information about purchasing the audio commentary containing the chapter displayed below, please click <a href="https://skousen2000.com/collections/all/mp3-download">here</a>.</Typography>
    }
    {(id < 549 || (id > 790 && id < 855) || id > 1038) && 
      <Typography style={{ textAlign: "center" }} variant="h6">Note: For information about purchasing the complete book containing the chapter displayed below, please click <a href="/products">here</a>.</Typography>
    }
    <div dangerouslySetInnerHTML={{__html: html}} />
  </>);
}