import { Box, Typography } from "@mui/material";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../components/footer";
import Header from "../components/header";

export default function Privacy() {
  return (<HelmetProvider>
    <Helmet title="Privacy Notice"/>
    <Header />
    <Box className="mainBox">
      <Box className="subMainBox">
        <h1>Privacy Notice</h1>
        <Box sx={{ borderRadius: "6px", backgroundColor: "white", padding: "0 20px 20px 20px", marginBottom: "50px", display: "flex", flexDirection: "column" }}>
          <h2>Our Commitment</h2>
          <Typography>We will not sell or distribute your information (i.e. your name or email address) to other third parties so that they can engage in unapproved marketing activies with you.</Typography>
          <Typography>We will contact you via email to give you status updates about your order (order received, order shipped, etc.) If needed, we might contact you by email about technical issues with the site.</Typography>
          <h2>Your Rights to Request Removal of Your Information</h2>
          <Typography>You have the right to contact us and request that all of your information be deleted from our order database. Upon receiving this request, your information will be purged from our order database within thirty (30) days of our company receiving your request.</Typography>
        </Box>
      </Box>
    </Box>
    <Footer />
  </HelmetProvider>);
}

